import { useState } from "react";
import { CreditCardInput } from "components/inputs/CreditCardInput";
import { ExpirationInput } from "components/inputs/ExpirationInput";
import { formatCardDetails } from "lib/format";

const fieldValue = (setter) => (ev) => setter(ev.target.value);

const PaymentMethodForm = (props) => {
  const { onSubmit } = props;

  const [cardNumber, setCardNumber] = useState("");
  const [expiration, setExpiration] = useState("");
  const [cvv, setCvv] = useState("");

  const [cardTouched, setCardTouched] = useState(false);
  const [expTouched, setExpTouched] = useState(false);
  const [cvvTouched, setCvvTouched] = useState(false);

  const expInvalid = (() => {
    if (expiration.length < 5) { 
      return true;
    }
    const [m, y] = expiration.split("/");
    const month = Number(m);
    if (month === 0 || month > 12) {
      return true;
    }
    const year = Number(`20${y}`);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // getMonth returns 0-11
    if (year < currentYear) {
      return true;
    }
    if (year === currentYear && month <= currentMonth) {
      return true;
    }
    return false;
  })();
  const expError = expTouched && expInvalid;

  // could validate the card number but it's a huge pain without a library
  const cardNumberInvalid = cardNumber.length < 16;
  const cardError = cardTouched && cardNumberInvalid;

  // cvv may be 3 or 4 digits
  const cvvInvalid = cvv.length < 3 || cvv.length > 4;
  const cvvError = cvvTouched && cvvInvalid;

  return (
    <form
      className="w-100"
      id="payment-method-form"
      onSubmit={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        onSubmit(formatCardDetails({ number: cardNumber, expiration, cvv }));
      }}
    >
      <div className="flex flex-column items-center w-100">
        <span className="flex flex-row flex-wrap justify-center" style={{gap: '1rem'}}>
          <span style={{width: '24ch'}}>
            <b className="f6 pb1 db">Credit card number</b>
            <CreditCardInput
              className={`w-100 pa3 mb2 f5 ${cardError ? "b--red" : ""}`}
              name="billing-cc-number"
              type="text"
              placeholder="0000 0000 0000 0000"
              autoComplete="cc-number"
              required
              onChange={setCardNumber}
              onFocus={() => setCardTouched(false)}
              onBlur={() => setCardTouched(true)}
            />
            <p className="f6 pa0 ma0 db">Only Visa and Mastercard accepted.</p>
          </span>
          <span className="flex flex-row justify-center w-100" style={{gap: '1rem'}}>
          <span style={{width:'9ch'}}>
            <b className="f6 pb1 db">Expiration</b>
            <ExpirationInput
              className={`input-reset w-100 pa3 mb2 mr3 mr0-ns f5 ${expError ? "b--red" : ""}`}
              name="billing-cc-exp"
              type="text"
              placeholder="00/00"
              maxLength="5"
              autoComplete="cc-exp"
              style={{ fontFamily: "Source Code Pro, monospace" }}
              pattern="\b\d{2}/\d{2}\b"
              required
              onChange={setExpiration}
              onFocus={() => setExpTouched(false)}
              onBlur={() => setExpTouched(true)}
            />
          </span>
          <span style={{width:'8ch'}}>
            <b className="f6 pb1 db">CVV</b>
            <input
              className={`input-reset w-100 pa3 mb2 f5 ${cvvError ? "b--red" : ""}`}
              name="cvv"
              type="text"
              placeholder="000"
              autoComplete="cc-csc"
              style={{ fontFamily: "Source Code Pro, monospace" }}
              pattern="\b\d{3,4}\b"
              required
              onChange={fieldValue(setCvv)}
              onFocus={() => setCvvTouched(false)}
              onBlur={() => setCvvTouched(true)}
            />
          </span>
          </span>
          <button
            className="mt3 input-reset br2 b--none w-75-ns pa3"
            type="submit"
            disabled={cardNumberInvalid || expInvalid || cvvInvalid}
          >
            Add Payment Method
          </button>
        </span>
      </div>
    </form>
  );
};

export { PaymentMethodForm };

