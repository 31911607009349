import * as openpgp from 'openpgp';
import { v4 as uuid } from 'uuid';
import { BACKEND_URL, CIRCLE_PUBKEY } from 'constants';
const api = `${BACKEND_URL}/paywall`;

async function getPublicationDetails({service, ship}) {
  const url = `${api}/details/${ship}/${service}`;
  const result = await fetch(url, {
    credentials: 'include',
  }).then(r => r.json());
  return result;
}

async function sendAuthEmail({email, service, ship, path, tier, url}) {
  const apiurl = `${api}/register-user`;
  const body = JSON.stringify({email, service, ship, path, tier, url });
  console.log('body', body);
  const result = await fetch(apiurl, {
    method: 'POST',
    body: body,
    credentials: 'include',
  }).then(r => r.json());
  return result;
}

async function authorizeToken({service, ship, token}) {
  const url = `${api}/token-auth`;
  const result = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({service, ship, token}),
    credentials: 'include',
  }).then(r => r.json());
  return result;
}

async function getSubscriptionStatus({token}) {
  const url = `${api}/subscription-status`;
  const result = await fetch(url, {
    headers: {token},
    credentials: 'include',
  }).then(r => r.json());
  return result;
}

async function getCard({token}) {
  const url = `${api}/get-card`;
  const result = await fetch(url, {
    headers: {token},
    credentials: 'include',
  }).then(r => r.json());
  return result;
}

async function createCard({shippingDetails, billingDetails, cardDetails, token}) {
  const url = `${api}/create-card`;

  const card = { number: cardDetails.number, cvv: cardDetails.cvv };

  const decodedPublicKey = await openpgp.readKey({
    armoredKey: atob(CIRCLE_PUBKEY),
  });
  const message = await openpgp.createMessage({ text: JSON.stringify(card) });
  const encryptedData = await openpgp.encrypt({
    message,
    encryptionKeys: decodedPublicKey,
  }).then(message => btoa(message));

  const body = {
    shippingDetails,
    billingDetails,
    email: billingDetails.email,
    expMonth: cardDetails.expMonth,
    expYear: cardDetails.expYear,
    encryptedData,
    idempotencyKey: uuid(),
    keyId: 'key1', //TODO get the proper key id from Circle's api
    token,
  }
  console.log('create-card', body);
  const result = await fetch(url, {
    method: 'POST',
    headers: { 'Content-type': 'text/json' },
    credentials: 'include',
    body: JSON.stringify(body),
  }).then(r => r.json());
  return result;
}

async function makePayment({token, payTier}) {
  console.log('makePaymentCall', token, payTier);
  const url = `${api}/create-payment`;
  const result = await fetch(url, {
    method: 'POST',
    headers: { 'Content-type': 'text/json' },
    credentials: 'include',
    body: JSON.stringify({token, "price-label": payTier}),
  }).then(r => r.json());
  return result;
}

async function getPayment({token}) {
  const url = `${api}/get-payment`;
  const result = await fetch(url, {
    headers: {token},
    credentials: 'include',
  }).then(r => r.json());
  return result;
}

export {
  authorizeToken,
  createCard,
  getPayment,
  getPublicationDetails,
  getSubscriptionStatus,
  getCard,
  makePayment,
  sendAuthEmail,
}
