import { useForm } from "react-hook-form";
import cn from 'clsx';
import noop from "lodash/noop";
import { useState } from 'react';

const emptyValues = {
  email: "",
};

const EmailForm = (props) => {
  const { onSubmit = noop, values = emptyValues, ...rest } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", defaultValues: values });

  let [value, setValue] = useState(null);
  let hasError = (Object.values(errors).length > 0);

  return (
    <form
      id="email-form"
      className="w-100 ph4 flex flex-column items-center"
      name="email"
      autoComplete="on"
      onSubmit={(e) => {
        handleSubmit(onSubmit)(e)}}
      {...rest}
    >
      <input
        className={`${cn({ error: hasError })} w-100 bg-dark-green white`}
        type="email"
        placeholder="johnny@apple.seed"
        onChange={(e) => {setValue(e.target.value)}}
        {...register("email", {
          required: true,
        })}
      />
      {errors?.email?.message && (
        <span className="error-message">{errors?.email?.message}</span>
      )}
      <button className="w-100" disabled={hasError}>Confirm Email</button>
    </form>
  );
};

export { EmailForm };
