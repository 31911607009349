import { useEffect, useState } from 'react';
import {
  useLoaderData,
  useSearchParams,
} from 'react-router-dom';
import { BillingDetailsForm } from 'components/BillingDetailsForm';
import { ShippingDetailsForm } from 'components/ShippingDetailsForm';
import { PaymentMethodForm } from 'components/PaymentMethodForm';
import { EmailForm } from 'components/EmailForm';
import { formatUSD } from 'lib/format';
//import { useFlowState } from 'lib/useFlowState';
import {
  authorizeToken,
  getCard,
  getPayment,
  makePayment,
  sendAuthEmail,
  createCard,
} from 'lib/backend';

// stage = Identify
//       | CheckSubscription
//       | Subscribed -> postMessage
//       | Form
// formStage = BillingDetails
//           | CardDetails
//           | CreateCard
//           | SubmitPayment

const Embed = () => {
  const [searchParams] = useSearchParams();
  const { details, service, ship } = useLoaderData();
  const { path, login, email, tier } =
    Object.fromEntries(searchParams.entries());

  const parentURL =
    (window.location !== window.parent.location)
    ? document.referrer
    : document.location.href;

  const [stage, setStage] = useState((login === 'null' || !login) ? 'start' : 'login');
  const [token, setToken] = useState((login === 'null' || !login) ? null : login);
  const [emailAddress, setEmailAddress] = useState(null);
  const [tierX, setTierX] = useState((tier === 'null') ? null : tier);
  const [billingDetails, setBillingDetails] = useState(null);
  const [shippingDetails, setShippingDetails] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);

  const [cardStatus, setCardStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);

  const cardPoll = () => {
    getCard({token}).then(r => {
      if (r?.status === 'complete') {
        setCardStatus(true);
        setStage('ready-to-pay');
      } else if (r?.status === 'failed') {
        setStage('card-failed')
      } else {
        setTimeout(cardPoll, 1000);
      }
    });
  }

  const payPoll = () => {
    getPayment({token}).then(r => {
      if (r?.status === 'complete') {
        setPaymentStatus(true);
        setStage('finale');
        window.top.postMessage({ "token": token, email: emailAddress, ship: ship }, "*")
      } else if (r?.status === 'failed') {
        setStage('payment-failed')
      } else {
        setTimeout(payPoll, 1000);
      }
    });
  }

  const step = (arg) => {

    let key = Object.keys(arg)[0];
    let val = arg[key];
    switch (stage) {
      case 'start':
        switch (key) {
          case 'email':
            setEmailAddress(val);
            setStage('pre-tier-request-signin')
            return;
          case 'select-tier':
            setTierX(val);
            setStage('email-form');
            return;
          default:
            return;
        }

      case 'email-form':
        switch (key) {
          case 'email':
            setEmailAddress(val);
            setStage('post-tier-request-signin')
            return;
          default:
            return;
        }

      case 'post-email-tier-select':
        switch (key) {
          case 'select-tier':
            setTierX(val);
            setStage('billing-details');
            return;
          default:
            return;
        }
      case 'billing-details':
        if (Object.keys(arg).length > 1) {
          setBillingDetails(arg);
          setStage('shipping-details');
        }
        return;
      case 'shipping-details':
        if (Object.keys(arg).length > 1) {
          setShippingDetails(arg);
          setStage('card-details');
        }
        return;
      case 'card-details':
        if (Object.keys(arg).length > 1) {
          setCardDetails(arg);
          setStage('submitting-card-details');
        }
        return;
      case 'ready-to-pay':
        switch (key) {
          case 'submit-payment':
            setStage('submitting-payment');
            return;
          default:
            return;
        }
      default:
        return;
    }
  }

  useEffect(() => {
    switch (stage) {
      case 'pre-tier-request-signin':
        sendAuthEmail({email: emailAddress, service, ship, path, tier: null, url: parentURL})
        .then(res => {
          setStage('signin-requested');
        });
        break;
      case 'post-tier-request-signin':
        sendAuthEmail({email: emailAddress, service, ship, path, tier: tierX, url: parentURL})
        .then(res => {
          setStage('signin-requested');
        });
        break;
      case 'login':
        authorizeToken({service, ship, token})
        .then(res => {
          if (res.tag === 'ok') {
            if (res.subscription !== null && res.subscription > Date.now()) {
              window.top.postMessage({ "token": token, email: res.email, ship: ship }, "*")
              setEmailAddress(res.email);
              setStage('logged-in')
            } else if (tierX !== null) {
              setStage('billing-details');
            } else {
              setStage('post-email-tier-select');
            }
          } else if (res.tag === 'error') {
            setStage('start');  // XX TODO better error handling
          }
        });
        break;
      case 'submitting-card-details':
        createCard({
          shippingDetails: {
            line2: null,
            ...shippingDetails,
          },
          billingDetails: {
            line2: null,
            ...billingDetails,
          },
          cardDetails,
          token
        })
        .then(res => {
          setStage('getting-card-status');
          cardPoll();
        });
        break;
      case 'submitting-payment':
        makePayment({token, payTier: tierX})
        .then(res => {
          setStage('getting-payment-status');
          payPoll();
        });
        break;
      default:
        break;
    }
  }, [stage]);


  return (
    <div style={{all: 'unset'}}>
          <Flow
            step={step}
            stage={stage}
            details={details}
            path={path}
            billingDetails={billingDetails}
            shippingDetails={shippingDetails}
            cardDetails={cardDetails}
            payTier={tierX}
            token={token}
            cardStatus={cardStatus}
            paymentStatus={paymentStatus}
          />
    </div>
  );
};

const animate = "animate__animated animate__fadeInRight animate__faster";

const Flow = props => {
  const {
    step,
    stage,
    details,
    path,
    billingDetails,
    shippingDetails,
    cardDetails,
    payTier,
    token,
    cardStatus,
    paymentStatus
  } = props;
  const [subscription, setSubscription] = useState(null);
  const [chosenTier, setChosenTier] = useState(null);
  const [reuseBilling, setReuseBilling] = useState(true);

  const sortedPricing = Object.values(details.pricing).sort((a, b) => {
    if (a.price > b.price) {
      return 1;
    } else {
      return -1;
    }
  })

//
//  /* We want to animate the transitions between stages here.
//   *
//   * Unfortunately, the Virtual DOM algorithm reads every element it has to
//   * draw as a set of {tagType, propertySet}, and so two elements with the same
//   * tag type and properties are functionally the same element. It won't be
//   * re-rendered.
//   *
//   * In order for the animations to work (when the root elements here are
//   * identical as they are now), we have to completely hide one element before
//   * we start displaying another. This useEffect and the conditional render
//   * below accomplish that. */
//  const [hide, setHide] = useState(false);
//  useEffect(() => {
//    let mnt = true;
//    setHide(true);
//    const timeout = setTimeout(() => {
//      if (!mnt) {
//        return;
//      }
//      setHide(false);
//    }, 100);
//    return () => {
//      mnt = false;
//      clearTimeout(timeout);
//    };
//  }, [stageLabel]);
//
//  if (hide) {
//    return (
//      <cover-l minHeight="20vh">
//      </cover-l>
//    );
//  }

  switch (stage) {


    case 'start':

    return (
          <div className="flex flex-row flex-wrap justify-center" style={{gap: '3rem'}}>
            { sortedPricing.map((p, i) => {
              let bg = (chosenTier === p.label) ? 'bg-washed-green b--black-60 bw2' : 'bg-white b--black-20';
              return (
                <div className="flex flex-row justify-between" style={{gap:'2rem'}}>
                  <div className="br4 pa3 w5 flex flex-column items-center" style={{backgroundColor: '#6D0718'}}>
                    <div className="b f4 flex justify-around mb2">
                      {p.label}
                    </div>
                      <div className="flex">
                        <span className="f2 b">
                          {formatUSD((p.price/100), { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </span>
                      </div>
                        <span className="f6 white mt0 mb3">per month</span>
                    <div className="f5 bg-white black ph3 pv2 mb3 br3 fw4 pointer grow"
                      onClick={() => {
                        step({'select-tier': p.label});
                      }}
                    >
                      Join
                    </div>
                    <div className="f5">
                      {p.copy.map(line => (
                        <p className="ma2">{line}</p>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
    );

    case 'finale':
    case 'logged-in':
      return (
        <div className="flex flex-column w-100 items-center">
          <h2>Welcome, you are subscribed to {details.title}</h2>
        </div>
      );
    case 'email-form':
      return (
        <div className="flex flex-column w-100 items-center">
            <div className="flex flex-column items-center">
            <h3 className="mb3 mt0">Sign up with email</h3>
            <EmailForm onSubmit={step}/>
          </div>
        </div>
      );
    case 'pre-tier-request-signin':
    case 'post-tier-request-signin':
      return (
        <div className="flex flex-column w-100 items-center">
          <div className="flex flex-row justify-between">
            <h3 className="mv0">Requesting sign in link...</h3>
          </div>
        </div>
      );
    case 'signin-requested':
      return (
        <div className="flex flex-column w-100 items-center">
          <div className="flex flex-row justify-between">
            <h3 className="mv0">A sign in link has been emailed to you</h3>
          </div>
        </div>
      );
    case 'login':
      return (
        <div className="flex flex-column w-100 items-center">
          <h3 className="mv0">
            Authorizing token...
          </h3>
        </div>
      );
    case 'post-email-tier-select':
      return (
        <div className="flex flex-column w-100 items-center">
          <div className="flex flex-column">
            <h3 className="mt0 mb3">
              Select a payment option
            </h3>
            <div className="flex flex-column w-100" style={{gap:'0.5rem'}}>
              { sortedPricing.map((p, i) => {
                let bg = (chosenTier === p.label) ? 'bg-washed-green b--black-60 bw2' : 'bg-white b--black-20';
                return (
                  <div className={`ba br4 pa3 w-100 pointer flex justify-between flex-wrap  ${bg}`} key={i}
                    onClick={(e) => {setChosenTier(p.label)}}
                  >
                    <div className="b f4 flex mb2 flex-wrap justify-between mw-100">
                      <div className="mr4">
                      {p.label}
                      </div>
                      <div className="flex">
                        <span className="f4 b">
                          {formatUSD(p.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                        </span>
                        <span className="f6 gray mt1 ml2 mr0 pr0 fw3">per month</span>
                      </div>
                    </div>
                    <div className="f5">
                      {p.copy.map(line => (
                        <p className="ma2">{line}</p>
                      ))}
                    </div>
                  </div>
                )
              })}
              <button disabled={!chosenTier}
                onClick={() => {
                  step({'select-tier': chosenTier});
                }}
              >
                Select option and continue
              </button>
            </div>
          </div>
        </div>
      );
    case 'billing-details':
      return (
        <div className="flex flex-column w-100 items-center">
          <h3 className="mv0">
            Next up is your billing details.
          </h3>
          <BillingDetailsForm
            className="flex flex-row flex-wrap justify-between items-center"
            style={{ gap: "var(--s1)" }}
            onSubmit={step}
          />
        </div>
      );
    case 'shipping-details':
      return (
        <div className="flex flex-column w-100 items-center">
          <h3 className="mv0">
            Next up is your shipping details.
          </h3>
        <span>
          Use billing address as shipping address:
          <input className="ml2 mt1"
            type="checkbox"
            checked={reuseBilling}
            onChange={(e) => setReuseBilling(e.target.checked)}
          />
          </span>
          {(reuseBilling) ? (
            <stack-l>
              <p className="ml1 mv0">
                {billingDetails.name}
                <br />
                {billingDetails.line1}
                <br />
                {billingDetails.city},&nbsp;{billingDetails.district}&nbsp;{billingDetails.postalCode}
                <br />
                {billingDetails.country}
              </p>
              <button
                onClick={(e) => {
                  step(billingDetails);
                }}
              >
                Confirm Shipping Address
              </button>
            </stack-l>
          ) : (
            <ShippingDetailsForm
              className="flex flex-row flex-wrap justify-between items-center"
              style={{ gap: "var(--s1)" }}
              onSubmit={step}
            />
          )}
        </div>
      );

    case 'card-details':
      return (
        <div className="flex flex-column w-100 items-center">
          <h3 className="mv0">
            And credit card information.
          </h3>
          <PaymentMethodForm onSubmit={step} />
        </div>
      );
    case 'submitting-card-details':
    case 'getting-card-status':
    case 'ready-to-pay':
      return (
        <div className="flex flex-column w-100 items-center">
          <h3 className="mv0">Make sure everything looks correct!</h3>
          <h4 className="mv0">Billing address:</h4>
          <p className="ml1 mv0">
            {billingDetails.name}
            <br />
            {billingDetails.line1}
            <br />
            {billingDetails.city},&nbsp;{billingDetails.district}&nbsp;{billingDetails.postalCode}
          </p>
          <h4 className="mv0">Shipping address:</h4>
          <p className="ml1 mv0">
            {shippingDetails.name}
            <br />
            {shippingDetails.line1}
            <br />
            {shippingDetails.city},&nbsp;{shippingDetails.district}&nbsp;{shippingDetails.postalCode}
          </p>
          <h4 className="mv0">Payment card:</h4>
          <p className="ml1 mv0">
            ****&nbsp;****&nbsp;****&nbsp;{cardDetails.number?.slice(-4)}
          </p>
          {!cardStatus && <p>Registering card...</p>}
          <button disabled={!cardStatus} onClick={(ev) => {
            step({'submit-payment': null});
          }} className="confirm mt3">
            Submit Payment
          </button>
        </div>
      );
    case 'submitting-payment':
    case 'getting-payment-status':
      return (
        <div className="flex flex-column w-100 items-center">
          <div className="flex flex-row justify-between">
           <h3>Submitting payment...</h3>
          </div>
        </div>
      );
    case 'payment-failed':
      return (
        <div className="flex flex-column w-100 items-center">
          {paymentStatus ? <>
            <h3>Payment successful!</h3>
            <button onClick={() => {
              window.top.postMessage({ "token": token }, "*")
            }}>Proceed to post</button>
          </>
            :
          <>
            <h3>Your payment could not be processed.</h3>
            <p>There was an error processing your payment.</p>
          </>
          }
        </div>
      );
    default:
      return <center-l intrinsic><p>Something went wrong</p></center-l>;
  }
};

export default Embed;
