import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  useRouteError,
  RouterProvider,
  Outlet,
  useSearchParams,
} from 'react-router-dom';
import 'tachyons';
import 'animate.css';
import '@yungcalibri/layout';
import '@yungcalibri/layout/umd/bundle.css';
import 'water.css/out/water.min.css'
import 'index.css';

import Tollbooth from 'Tollbooth';
import Embed from 'Embed';
import { getPublicationDetails } from 'lib/backend';
import reportWebVitals from './reportWebVitals';

const BadUrl = () => {
  return (
    <cover-l minHeight="80vh" centered="h1">
      <h1>
        That's a bad URL.
      </h1>
      <p>
        <code>{window.location.href}</code> isn't a real page.
      </p>
    </cover-l>
  );
};

const Error = () => {
  const err = useRouteError();
  console.error(err);
  return (
    <center-l>
      <cover-l minHeight="30vh">
        <h1>That's an error.</h1>
        <p>Check the JavaScript console for more info.</p>
      </cover-l>
    </center-l>
  );
};

const Frame = () => {
  const [searchParams] = useSearchParams();
  const { background, textBright, textMain, links, path, login, email, tier } =
    Object.fromEntries(searchParams.entries());
  const [styled, setStyled] = useState(false);
  useEffect(() => {
    if ((background || textBright || textMain || links) && !styled) {
      [
        ["--background-body", background],
        ["--text-bright", textBright],
        ["--text-main", textMain],
        ["--links", links]
      ].forEach((color) => {
        document.documentElement.style.setProperty(
          color[0],
          color[1]?.startsWith("#") ? color[1] : `#${color[1]}`);
      })
      setStyled(true);
    }
  }, [background, textBright, textMain, links, styled])
  return (
    <div className="bg-green br4">
      <div>
        <Outlet context={[path, login, email, tier]}/>
      </div>
      <div>
        <div className="flex flex-row justify-center">
          <div>
            <p className="f6 white">
              Published with&nbsp;
              <a
                className="white underline"
                href="https://urbit.studio"
                rel="noopener noreferrer"
                target="_blank"
              >
                Urbit Studio
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

const router = createBrowserRouter([
  {
    element: <Frame />,
    children: [
      {
        path: '/login/:ship/:service',
        loader: async ({ params }) => {
          /* get ship and service details from backend server */
          const { ship, service } = params;
          const details = await getPublicationDetails({ ship, service });
          return { details, ship, service };
        },
        errorElement: <Error />,
        element: <Tollbooth />,
      },
      {
        path: '/*',
        index: true,
        element: <BadUrl />
      },
    ]
  },
  { path: '/embed/:ship/:service',
    loader: async ({ params }) => {
      /* get ship and service details from backend server */
      const { ship, service } = params;
      const details = await getPublicationDetails({ ship, service });
      return { details, ship, service };
    },
    errorElement: <Error />,
    element: <Embed />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
