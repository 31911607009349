import { useEffect, useState } from 'react';
import { useLoaderData, useOutletContext } from 'react-router-dom';
import noop from 'lodash/noop';
import { BillingDetailsForm } from 'components/BillingDetailsForm';
import { ShippingDetailsForm } from 'components/ShippingDetailsForm';
import { PaymentMethodForm } from 'components/PaymentMethodForm';
import { EmailForm } from 'components/EmailForm';
import { formatUSD } from 'lib/format';
//import { useFlowState } from 'lib/useFlowState';
import {
  authorizeToken,
  getCard,
  getPayment,
  makePayment,
  sendAuthEmail,
  createCard,
} from 'lib/backend';

// stage = Identify
//       | CheckSubscription
//       | Subscribed -> postMessage
//       | Form
// formStage = BillingDetails
//           | CardDetails
//           | CreateCard
//           | SubmitPayment

const Tollbooth = () => {
  const { details, service, ship } = useLoaderData();

  const [ path, login, email, tierParam ] = useOutletContext();

//  console.log(details, service, ship, path, login, email);

  const [stage, setStage] = useState((login === 'null') ? 'start' : 'login');
  const [token, setToken] = useState((login === 'null') ? null : login);
  const [emailAddress, setEmailAddress] = useState(null);
  const [tier, setTier] = useState((tierParam === 'null') ? null : tierParam);
  const [billingDetails, setBillingDetails] = useState(null);
  const [shippingDetails, setShippingDetails] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);

  const [cardStatus, setCardStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);

  const cardPoll = () => {
    getCard({token}).then(r => {
      if (r?.status === 'complete') {
        setCardStatus(true);
        setStage('ready-to-pay');
      } else if (r?.status === 'failed') {
        setStage('card-failed')
      } else {
        setTimeout(cardPoll, 1000);
      }
    });
  }


  const payPoll = () => {
    getPayment({token}).then(r => {
      if (r?.status === 'complete') {
        setPaymentStatus(true);
        setStage('finale');
      } else if (r?.status === 'failed') {
        setStage('payment-failed')
      } else {
        setTimeout(payPoll, 1000);
      }
    });
  }

  const step = (arg) => {

    let key = Object.keys(arg)[0];
    let val = arg[key];
    switch (stage) {
      case 'start':
        switch (key) {
          case 'email':
            setEmailAddress(val);
            setStage('pre-tier-request-signin')
            return;
          case 'select-tier':
            setTier(val);
            setStage('email-form');
            return;
          default:
            return;
        }

      case 'email-form':
        switch (key) {
          case 'email':
            setEmailAddress(val);
            setStage('post-tier-request-signin')
            return;
          default:
            return;
        }

      case 'post-email-tier-select':
        switch (key) {
          case 'select-tier':
            setTier(val);
            setStage('billing-details');
            return;
          default:
            return;
        }
      case 'billing-details':
        if (Object.keys(arg).length > 1) {
          setBillingDetails(arg);
          setStage('shipping-details');
        }
        return;
      case 'shipping-details':
        if (Object.keys(arg).length > 1) {
          setShippingDetails(arg);
          setStage('card-details');
        }
        return;
      case 'card-details':
        if (Object.keys(arg).length > 1) {
          setCardDetails(arg);
          setStage('submitting-card-details');
        }
        return;
      case 'ready-to-pay':
        switch (key) {
          case 'submit-payment':
            setStage('submitting-payment');
            return;
          default:
            return;
        }
      default:
        return;
    }
  }

  useEffect(() => {
    switch (stage) {
      case 'pre-tier-request-signin':
        sendAuthEmail({email: emailAddress, service, ship, path, tier: null, url: null})
        .then(res => {
          setStage('signin-requested');
        });
        break;
      case 'post-tier-request-signin':
        sendAuthEmail({email: emailAddress, service, ship, path, tier, url: null})
        .then(res => {
          setStage('signin-requested');
        });
        break;
      case 'login':
        authorizeToken({email, service, ship, token})
        .then(res => {
          if (res.tag === 'ok') {
            if (res.subscription !== null && res.subscription > Date.now()) {
              window.top.postMessage({ "token": token }, "*")
            } else if (tier !== null) {
              setStage('billing-details');
            } else {
              setStage('post-email-tier-select');
            }
          } else if (res.tag === 'error') {
            setStage('start');  // XX TODO better error handling
          }
        });
        break;
      case 'submitting-card-details':
        createCard({
          shippingDetails: {
            line2: null,
            ...shippingDetails,
          },
          billingDetails: {
            line2: null,
            ...billingDetails,
          },
          cardDetails,
          token
        })
        .then(res => {
          setStage('getting-card-status');
          cardPoll();
        });
        break;
      case 'submitting-payment':
        makePayment({token, payTier: tier})
        .then(res => {
          setStage('getting-payment-status');
          payPoll();
        });
        break;
      default:
        break;
    }
  }, [stage]);


  return (
    <div className="pa5-ns pa2">
      <sidebar-l sideWidth="28ch" noStretch>
        <div className="overflow:hidden">
          <Flow
            step={step}
            stage={stage}
            details={details}
            path={path}
            billingDetails={billingDetails}
            shippingDetails={shippingDetails}
            cardDetails={cardDetails}
            payTier={tier}
            token={token}
            cardStatus={cardStatus}
            paymentStatus={paymentStatus}
          />
        </div>
      </sidebar-l>
    </div>
  );
};

const animate = "animate__animated animate__fadeInRight animate__faster";

const Flow = props => {
  const {
    step,
    stage,
    details,
    path,
    billingDetails,
    shippingDetails,
    cardDetails,
    payTier,
    token,
    cardStatus,
    paymentStatus
  } = props;
  const [subscription, setSubscription] = useState(null);
  const [chosenTier, setChosenTier] = useState(null);
  const [reuseBilling, setReuseBilling] = useState(true);

  const sortedPricing = Object.values(details.pricing).sort((a, b) => {
    if (a.price > b.price) {
      return 1;
    } else {
      return -1;
    }
  })

  let tier = details.pricing.find((t) => {return t.label === payTier});
  switch (stage) {
    case 'start':
      return (
        <div className="flex flex-column">
          <div className="flex flex-row justify-center">
            <h1 className="mb4 mt1 white fw3">
              This post is for paid subscribers
            </h1>
          </div>
          <div className="flex flex-wrap w-100 justify-between">
            <div className="flex flex-column w-50-ns">
              <div className="flex flex-row justify-center">
                <h2 className="mt0 mb2">
                  <span className="fw3 white">Subscribe</span>
                </h2>
              </div>
              <div className="flex flex-column" style={{gap:'0.5rem'}}>
                { sortedPricing.map((p, i) => {
                  let bg = (chosenTier === p.label) ? 'b--black-60 bw2' : 'b--black-20';
                  return (
                    <div className={`ba br4 pa3 w-100 pointer flex flex-column ${bg}`} key={i}
                      onClick={(e) => {setChosenTier(p.label)}}
                    >
                      <div className="b f4 flex flex-wrap justify-between mb2">
                        <div className="mr4 white">
                        {p.label}
                        </div>
                        <div className="flex">
                          <span className="f4 b white">
                            {formatUSD(p.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                          </span>
                          <span className="f6 mt1 ml2 mr0 pr0 fw3 white">per month</span>
                        </div>
                      </div>
                      <div className="f5">
                        {p.copy.map(line => (
                          <p className="ma2 white">{line}</p>
                        ))}
                      </div>
                    </div>
                  )
                })}
                <button disabled={!chosenTier}
                  className="mb4"
                  onClick={() => {
                    step({'select-tier': chosenTier});
                  }}
                >
                  Select Tier and Continue
                </button>
              </div>
            </div>
            <div className="flex flex-column items-center w-50-ns">
              <h2 className="mt0 mb2 fw3 white">Sign in</h2>
              <EmailForm onSubmit={step}/>
            </div>
          </div>
        </div>
      );
    case 'email-form':
      return (
        <stack-l class={animate}>
          <div className="flex flex-column items-center">
          <h3 className="mb3 mt0 white">Sign up with email</h3>
          <EmailForm onSubmit={step}/>
          </div>
        </stack-l>
      );
    case 'pre-tier-request-signin':
    case 'post-tier-request-signin':
      return (
        <stack-l class={animate}>
          <div className="flex flex-row justify-center">
            <h1 className="mv0 fw3 white">Requesting sign in link...</h1>
          </div>
        </stack-l>
      );
    case 'signin-requested':
      return (
        <stack-l class={animate}>
          <div className="flex flex-row justify-center">
            <h1 className="mv0 fw3 white">A sign in link has been emailed to you.</h1>
          </div>
        </stack-l>
      );
    case 'login':
      return (
        <stack-l class={animate}>
          <h3 className="mv0 white">
            Authorizing token...
          </h3>
        </stack-l>
      );
    case 'post-email-tier-select':
      return (
        <stack-l class={animate}>
          <div className="flex flex-column">
            <div className="flex flex-row justify-center">
              <h1 className="mt1 mb4 fw3 white">
                Select Subscription Tier
              </h1>
            </div>
            <div className="flex flex-column w-100" style={{gap:'0.5rem'}}>
              { sortedPricing.map((p, i) => {
                let bg = (chosenTier === p.label) ? 'b--black-60 bw2' : 'b--black-20';
                return (
                  <div className={`ba br4 pa3 w-100 pointer flex flex-column flex-wrap ${bg}`} key={i}
                    onClick={(e) => {setChosenTier(p.label)}}
                  >
                    <div className="b f4 flex mb2 flex-wrap justify-between mw-100">
                      <div className="mr4 white">
                      {p.label}
                      </div>
                      <div className="flex">
                        <span className="f4 b white">
                          {formatUSD(p.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                        </span>
                        <span className="f6 mt1 ml2 mr0 pr0 fw3 white">per month</span>
                      </div>
                    </div>
                    <div className="f5">
                      {p.copy.map(line => (
                        <p className="ma2 white">{line}</p>
                      ))}
                    </div>
                  </div>
                )
              })}
              <button disabled={!chosenTier}
                onClick={() => {
                  step({'select-tier': chosenTier});
                }}
              >
                Select and Continue
              </button>
            </div>
          </div>
        </stack-l>
      );
    case 'billing-details':
      return (
        <div className="flex flex-row flex-wrap">
          <div className="flex flex-column w-50-ns w-100 ph4 mb5 items-center">
            <div className="flex flex-row justify-center">
            </div>
            <div className="flex flex-column w-100 white">
              <h2 className="fw3 mt1 mb4 white">
                Summary
              </h2>
              <h3 className="mt0 mb3 white">
                {details?.title}
              </h3>
              <div className="flex flex-row justify-between mb1">
                <p className="ma0 pa0 b">Subscription</p>
                <p className="ma0 pa0 b">
                  {formatUSD(tier.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                </p>
              </div>
              <div>
                <p className="ml3 mv2 f5">
                  {tier.label}
                </p>
                <p className="ml3 mv2 f5">
                  Monthly Renewal
                </p>
              </div>
              <div className="flex flex-row justify-between bt pt3 mt3">
                <p className="ma0 pa0 b">Total</p>
                <p className="ma0 pa0 b">
                  {formatUSD(tier.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-column w-50-ns">
            <div className="flex flex-row justify-center">
              <h2 className="mt1 mb4 fw3 white">
                Billing Address
              </h2>
            </div>
            <div className="flex flex-row justify-center">
              <BillingDetailsForm
                className="flex flex-row flex-wrap justify-between items-center w-75 white"
                style={{ gap: "var(--s1)" }}
                onSubmit={step}
              />
            </div>
          </div>
        </div>
      );
    case 'shipping-details':
      return (
        <div className="flex flex-row flex-wrap">
          <div className="flex flex-column w-50-ns w-100 ph4 mb5 items-center">
            <div className="flex flex-row justify-center">
            </div>
            <div className="flex flex-column w-100 white">
              <h2 className="fw3 mt1 mb4 white">
                Summary
              </h2>
              <h3 className="mt0 mb3 white">
                {details?.title}
              </h3>
              <div className="flex flex-row justify-between mb1">
                <p className="ma0 pa0 b">Subscription</p>
                <p className="ma0 pa0 b">
                  {formatUSD(tier.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                </p>
              </div>
              <div>
                <p className="ml3 mv2 f5">
                  {tier.label}
                </p>
                <p className="ml3 mv2 f5">
                  Monthly Renewal
                </p>
              </div>
              <div className="flex flex-row justify-between bt pt3 mt3">
                <p className="ma0 pa0 b">Total</p>
                <p className="ma0 pa0 b">
                  {formatUSD(tier.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-column items-center w-50-ns white">
            <h2 className="mt1 mb4 fw3 white">
              Shipping Address
            </h2>
            <span className="mb2">
              Ship to Billing Address:
              <input className="ml2 mt1"
                type="checkbox"
                checked={reuseBilling}
                onChange={(e) => setReuseBilling(e.target.checked)}
              />
            </span>
          {(reuseBilling) ? (
            <div className="flex flex-column items-center">
              <p className="ml1 mt0 mb4">
                {billingDetails.name}
                <br />
                {billingDetails.line1}
                <br />
                {billingDetails.city},&nbsp;{billingDetails.district}&nbsp;{billingDetails.postalCode}
                <br />
                {billingDetails.country}
              </p>
              <button
                onClick={(e) => {
                  step(billingDetails);
                }}
              >
                Confirm Shipping Address
              </button>
            </div>
          ) : (
            <div className="flex flex-row justify-center">
              <ShippingDetailsForm
                className="flex flex-row flex-wrap justify-between items-center w-75-ns mt2"
                style={{ gap: "var(--s1)" }}
                onSubmit={step}
              />
            </div>
          )}
          </div>
        </div>
      );

    case 'card-details':
      return (
        <div className="flex flex-row flex-wrap">
          <div className="flex flex-column w-50-ns w-100 ph4 mb5 items-center">
            <div className="flex flex-row justify-center">
            </div>
            <div className="flex flex-column w-100 white">
              <h2 className="fw3 mt1 mb4 white">
                Summary
              </h2>
              <h3 className="mt0 mb3 white">
                {details?.title}
              </h3>
              <div className="flex flex-row justify-between mb1">
                <p className="ma0 pa0 b">Subscription</p>
                <p className="ma0 pa0 b">
                  {formatUSD(tier.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                </p>
              </div>
              <div>
                <p className="ml3 mv2 f5">
                  {tier.label}
                </p>
                <p className="ml3 mv2 f5">
                  Monthly Renewal
                </p>
              </div>
              <div className="flex flex-row justify-between bt pt3 mt3">
                <p className="ma0 pa0 b">Total</p>
                <p className="ma0 pa0 b">
                  {formatUSD(tier.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-column items-center w-50-ns white">
            <h2 className="mt1 mb4 fw3 white">
              Card Details
            </h2>
            <PaymentMethodForm
              onSubmit={step}
            />
          </div>
        </div>
      );
    case 'submitting-card-details':
    case 'getting-card-status':
    case 'ready-to-pay':
      return (
        <div className="flex flex-row flex-wrap">
          <div className="flex flex-column w-50-ns w-100 ph4 mb5 items-center">
            <div className="flex flex-row justify-center">
            </div>
            <div className="flex flex-column w-100 white">
              <h2 className="fw3 mt1 mb4 white">
                Summary
              </h2>
              <h3 className="mt0 mb3 white">
                {details?.title}
              </h3>
              <div className="flex flex-row justify-between mb1">
                <p className="ma0 pa0 b">Subscription</p>
                <p className="ma0 pa0 b">
                  {formatUSD(tier.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                </p>
              </div>
              <div>
                <p className="ml3 mv2 f5">
                  {tier.label}
                </p>
                <p className="ml3 mv2 f5">
                  Monthly Renewal
                </p>
              </div>
              <div className="flex flex-row justify-between bt pt3 mt3">
                <p className="ma0 pa0 b">Total</p>
                <p className="ma0 pa0 b">
                  {formatUSD(tier.price / 100, {maximumFractionDigits:2, minimumFractionDigits:2})}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-column items-center w-50-ns white">
            <h2 className="mt1 mb4 fw3 white">
              Confirm Details
            </h2>
            <div className="flex flex-column items-center">
              <h4 className="mv0 white">Billing address:</h4>
              <p className="ml1 mv0">
                {billingDetails.name}
                <br />
                {billingDetails.line1}
                <br />
                {billingDetails.city},&nbsp;{billingDetails.district}&nbsp;{billingDetails.postalCode}
              </p>
              <h4 className="mb0 mt4 white">Shipping address:</h4>
              <p className="ml1 mv0">
                {shippingDetails.name}
                <br />
                {shippingDetails.line1}
                <br />
                {shippingDetails.city},&nbsp;{shippingDetails.district}&nbsp;{shippingDetails.postalCode}
              </p>
              <h4 className="mb0 mt4 white">Payment card:</h4>
              <p className="ml1 mv0">
                ****&nbsp;****&nbsp;****&nbsp;{cardDetails.number?.slice(-4)}
              </p>
              {!cardStatus && <p>Registering card...</p>}
              <button disabled={!cardStatus} onClick={(ev) => {
                step({'submit-payment': null});
              }} className="confirm mt3">
                Submit Payment
              </button>
            </div>
          </div>
        </div>
      );
    case 'submitting-payment':
    case 'getting-payment-status':
      return (
        <stack-l class={animate}>
          <div className="flex flex-column items-center">
            <h1 className="mt1 mb4 fw3 white">
              Submitting payment...
            </h1>
          </div>
        </stack-l>
      );
    case 'finale':
    case 'payment-failed':
      return (
        <stack-l space="var(--s0)" class={animate}>
          {paymentStatus ? <>
            <div className="flex flex-column items-center white">
              <h1 className="mt1 mb4 fw3 white">
                Payment Successful - Thank You!
              </h1>
              <button className="w-75-ns" onClick={() => {
                window.top.postMessage({ "token": token }, "*")
              }}>Continue to Post</button>
            </div>
          </>
            :
          <>
            <h3 className="white">Your payment could not be processed.</h3>
            <p>There was an error processing your payment.</p>
          </>
          }
        </stack-l>
      );
    default:
      return <center-l intrinsic><p>Something went wrong</p></center-l>;
  }
};

export default Tollbooth;
