import { useRef } from 'react';

function createMask(string){
  if (string.length < 2) {
    return string.replace(/^(\d{2})$/, "$1");
  }
  if (string.length === 2) {
    return string.replace(/^(\d{2})$/, "$1/");
  }
  if (string.length <= 4) {
    return string.replace(/^(\d{2})(\d{1,2})$/, "$1/$2");
  }
  return string.substring(0, 4).replace(/^(\d{2})(\d{1,2})$/, "$1/$2");
}

function destroyMask(string){
  return string.replace(/\D/g,'').substring(0, 4);
}

const ExpirationInput = ({ onChange, ...rest }) => {
  const mask = useRef('');
  const num = useRef('');

  const handleChange = (evt) => {
    num.current = destroyMask(evt.target.value);
    mask.current = createMask(num.current);
    onChange(mask.current);
  };

  return (
    <input
      onChange={handleChange}
      style={{ fontFamily: 'Source Code Pro, monospace' }}
      value={mask.current}
      {...rest}
    />
  );
};

export { ExpirationInput };

