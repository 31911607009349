export const formatUSD = (price, options = {}) => {
  const fmt = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...options,
  });
  return fmt.format(price);
};

export const formatCardDetails = (rawCardData) => {
  const [expMonth, expYear] = rawCardData.expiration.split('/');
  const out = {
    number: Number(rawCardData.number),
    cvv: Number(rawCardData.cvv),
    expMonth: Number(expMonth),
    expYear: Number(`20${expYear}`),
  }
  if ([out.number, out.cvv, out.expMonth, out.expYear].some(v => isNaN(v))) {
    throw new Error('failed to parse card details');
  }
  return {
    number: rawCardData.number,
    cvv: rawCardData.cvv,
    expMonth: Number(expMonth),
    expYear: Number(`20${expYear}`),
  };
}
