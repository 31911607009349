import { useForm } from "react-hook-form";
import cn from "clsx";
import noop from 'lodash/noop';
import countries from "constants/countries.json";

const emptyValues = {
  name: "",
  line1: "",
  city: "",
  district: "",
  postalCode: "",
};

const ShippingDetailsForm = (props) => {
  const { onSubmit = noop, values = emptyValues, ...rest } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    mode: "onBlur",
    defaultValues: values,
  });

  const hasError = Object.values(errors).length;

  return (
    <form
      id="shipping-details-form"
      name="shipping-details"
      autoComplete="on"
      onSubmit={(e) => {
        console.log('shipping-details-form')
        handleSubmit(onSubmit)(e)}}
      {...rest}
    >
      <span>
        <label className="mb1" htmlFor="name">
          Full name
        </label>
        <input
          className={cn("f5", { error: errors?.name })}
          type="text"
          placeholder="John Smith"
          autoComplete="cc-given-name"
          id="name"
          {...register("name", {
            required: true,
            pattern: {
              value: /(?:\w+ )+\w+/,
              message: "full name as it appears on your credit card",
            },
          })}
        />
        {errors?.name?.message && (
          <span className="error-message">{errors?.name?.message}</span>
        )}
      </span>
      <span>
        <label className="mb1" htmlFor="line1">
          Shipping Address
        </label>
        <input
          className={cn("f5", { error: errors?.line1 })}
          type="text"
          placeholder="200 Cherry Ln"
          autoComplete="line1"
          id="line1"
          {...register("line1", {
            required: true,
          })}
        />
        {errors?.line1?.message && (
          <span className="error-message">
            {errors?.line1?.message}
          </span>
        )}
      </span>
      <span>
        <label className="mb1" htmlFor="city">
          City
        </label>
        <input
          className={cn("f5", { error: errors?.city })}
          type="text"
          autoComplete="address-level2"
          placeholder="Verduria"
          id="city"
          {...register("city", {
            required: true,
          })}
        />
        {errors?.city?.message && (
          <span className="error-message">
            {errors?.city?.message}
          </span>
        )}
      </span>
      <span className="district">
        <label className="mb1" htmlFor="district">
          State/District
        </label>
        <input
          className={cn("f5", { error: errors?.district })}
          type="text"
          placeholder="MN"
          autoComplete="address-level1"
          id="district"
          {...register("district", {
            required: true,
            pattern: {
              value: /^[a-zA-Z]{2,3}$/,
              message: "short province or state code",
            },
          })}
        />
        {errors?.district?.message && (
          <span className="error-message">
            {errors?.district?.message}
          </span>
        )}
      </span>
      <span className="postal">
        <label className="mb1" htmlFor="postalCode">
          Postal code
        </label>
        <input
          className={cn("f5", { error: errors?.postalCode })}
          type="text"
          placeholder="88888"
          autoComplete="postal-code"
          id="postalCode"
          {...register("postalCode", {
            required: true,
          })}
        />
        {errors?.postalCode?.message && (
          <span className="error-message">
            {errors?.postalCode?.message}
          </span>
        )}
      </span>
      <span>
        <label className="mb1" htmlFor="country">
          Country
        </label>
        <select
          className={cn("f5", {
            error: errors?.country,
            black: touchedFields?.country,
            silver: !touchedFields?.country,
          })}
          autoComplete="country-name"
          id="country"
          {...register("country", {
            required: true,
          })}
        >
          {countries.map(({ value, text }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </select>
        {errors?.country?.message && (
          <span className="error-message">
            {errors?.country?.message}
          </span>
        )}
      </span>
      <button
        type="submit"
        disabled={hasError}
      >
        Confirm Shipping Address
      </button>
    </form>
  );
};

export { ShippingDetailsForm };
